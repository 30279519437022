import { Spinner } from "@chakra-ui/react";
import { CSSProperties } from "react";

export function Loading(props: {
  text?: string,
  height?: string,
  style?: CSSProperties,
  fullScreen?: boolean,
}) {
  return (
    <div className="common-loading" style={{
      height: props.height || 'auto',
      ...(props.fullScreen ? {
        height: '100vh',
        width: '100vw',
      } : {}),
      ...props.style,
    }}>
      <Spinner size={'lg'} />
      <p>{props.text || '加载中...'}</p>
    </div>
  )
}