import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';

export const history = createBrowserHistory();

export function initAnalyticsOnHistory() {
  history.listen(location => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })
  return;
}
