import { Tabs, TabList, Tab, TabPanels, TabPanel, Button, Icon } from "@chakra-ui/react";
import { useObserver } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { PortalApi } from "../../../../api/portal";
import { Loading } from "../../../../components/loading";
import { Portal } from "../../../../models/portal";
import { useStore } from "../../../../stores/content";
import { history } from "../../../../utils/history";
import { StudioPortalCard } from "../../componenets/portalCard";
import { StudioPageHeader } from "./components/studioPageHeader";

export default function StudioPortalListPage() {
  const store = useStore();
  const selectedPortal = useObserver(() => store.portal);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [myPortals, setMyPortals] = useState<Portal[]>([]);
  const [loading, setLoading] = useState(false);

  const loadPortals = async () => {
    setLoading(true);
    const portals = await PortalApi.getMyPortals();
    setMyPortals(portals);
    setLoading(false);
  }

  useEffect(() => {
    loadPortals();
  }, [])

  return (
    <>
      <div className="studio-page">
        <Tabs size="lg" index={tabIndex} onChange={(i: number) => setTabIndex(i)}>
          <div className="container">
            <StudioPageHeader title="我的页面" />
            <TabList>
              <Tab>页面列表</Tab>
            </TabList>
          </div>

          <TabPanels>
            <TabPanel>
              <div className="container">
                <Button leftIcon={<Icon as={MdAdd} />} onClick={() => {
                  history.push('/studio/portal/create');
                }}>新建页面</Button>
              </div>
              <div className="container">
                {loading ? (
                  <Loading  />
                ) : null}
                <div className="portal-list-box">
                  {myPortals.map((portal) => {
                    return (
                      <StudioPortalCard key={portal.id} selected={portal.id === (selectedPortal ? selectedPortal.id : null)} portal={portal} />
                    );
                  })}
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>

      </div>
    </>
  );
};
