import { Route } from "react-router-dom";
import { StudioLayout } from "./layout/studioLayout";
import { RequireLogin } from "./layout/requireLogin";
import { lazy, ReactNode, Suspense } from "react";
import StudioPortalListPage from "./pages/portal/list";
import { Loading } from "../../components/loading";

const StudioAccountHomePage = lazy(() => import("./pages/account"));
const StudioHomePage = lazy(() => import("./pages/home"));
const StudioPortalEditorPage = lazy(() => import("./pages/portal/editor"));
const StudioPortAnalyticsPage = lazy(() => import("./pages/portal/analytics"));
const StudioPortalSharePage = lazy(() => import("./pages/portal/share"));
const StudioAboutPage = lazy(() => import("./pages/statics/about"));
const StudioLoginPage = lazy(() => import("./pages/auth/login"));
const StudioRegisterPage = lazy(() => import("./pages/auth/register"));
const StudioPortalCreatePage = lazy(() => import("./pages/portal/create"));

const suspenseEl = (component: ReactNode) => {
  return <Suspense fallback={<Loading height={'100vh'} />}>{component}</Suspense>
}

export function StudioModule() {
  return (
    <Route
      path="/studio"
      element={
        suspenseEl(<StudioLayout />)
      }
    >

      <Route path="" element={<RequireLogin />}>
        <Route index element={suspenseEl(<StudioHomePage />)} />
        <Route path="portal" element={suspenseEl(<StudioPortalListPage />)} />
        <Route path="portal/create" element={suspenseEl(<StudioPortalCreatePage />)} />
        <Route path="portal/edit" element={suspenseEl(<StudioPortalEditorPage />)} />
        <Route path="portal/analytics" element={suspenseEl(<StudioPortAnalyticsPage />)} />
        <Route path="portal/share" element={suspenseEl(<StudioPortalSharePage />)} />
        <Route path="account" element={suspenseEl(<StudioAccountHomePage />)} />
      </Route>
      
      <Route path="login" element={suspenseEl(<StudioLoginPage />)} />
      <Route path="register" element={suspenseEl(<StudioRegisterPage />)} />
      <Route path="about" element={suspenseEl(<StudioAboutPage />)} />
    </Route>
  )
}