import { createStore, IStore } from "./store";
import { observable } from "mobx";
import React from "react";

const storeContext = React.createContext<IStore | null>(null);

export const StoreProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[] | null;
}) => {
  const store = observable(createStore());
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};
