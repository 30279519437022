import { AuthApi } from "../api/auth";
import { PortalApi } from "../api/portal";
import { request } from "../api/request";
import { Portal } from "../models/portal";
import { User } from "../models/user";
import { history } from "../utils/history";

export function createStore() {
  return {
    user: null as User | null,
    setUser(user: User | null) {
      this.user = user;
    },
    async reloadUser() {
      if(!this.user || !this.token) return;
      const data = await AuthApi.getSessionInfo();
      this.setUser(data.user);
    },

    portal: null as Portal | null,
    setPortal(portal: Portal | null) {
      this.portal = portal;
    },
    async reloadPortal() {
      if(!this.portal) return;
      const portal = await PortalApi.getPortal(this.portal.url);
      if(portal) this.setPortal(portal);
    },

    token: null as string | null,
    setToken(token: string) {
      this.token = token;
      localStorage.setItem('readme-token', token);
      request.setToken(token);
    },
    async resumeUserSession() {
      if (this.token) return;
      let token = localStorage.getItem('readme-token');
      if (token) {
        this.setToken(token);
        const data = await AuthApi.getSessionInfo();
        this.setUser(data.user);
      }
    },

    logout() {
      this.user = null;
      this.token = null;
      localStorage.removeItem('readme-token');
      history.push('/studio/login');
    }
  }
}

export type IStore = ReturnType<typeof createStore>;
