import { Button, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from "@chakra-ui/react";
import { useObserver } from "mobx-react-lite";
import { useEffect, useState, useCallback } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { PortalApi } from "../../../api/portal";
import { Portal } from "../../../models/portal";
import { useStore } from "../../../stores/content";
import { history } from "../../../utils/history";
import { StudioPortalCard } from "./portalCard";

export function PortalSelector(props: {}) {
  const store = useStore();
  const user = useObserver(() => store.user);
  const selectedPortal = useObserver(() => store.portal);
  const [isLoading, setIsLoading] = useState(false);

  const [isShowSelectedPortal, setIsShowSelectedPortal] = useState<boolean>(false);

  const trySelectPortal = useCallback(async () => {
    setIsLoading(true);
    const portals = await PortalApi.getMyPortals();
    if (portals[0]) store.setPortal(portals[0])
    setIsLoading(false);
  }, [store]);

  useEffect(() => {
    if (!user || selectedPortal) return;
    trySelectPortal();
  }, [selectedPortal, trySelectPortal, user])

  return (
    <div className="">
      {isShowSelectedPortal ? (
        <PortalSelectorModal onClose={() => {
          setIsShowSelectedPortal(false)
        }} />
      ) : null}
      <div className="portal-selector" onClick={() => {
        if(!user) return;
        setIsShowSelectedPortal(true);
      }}>
        <div className="content">

          {isLoading ? (
            <div style={{ marginRight: '15px' }}>
              <Spinner />
            </div>
          ) : null}
          <div>
            <h4>{selectedPortal ? selectedPortal.name : '[未选择页面]'}</h4>
            <p>点击切换页面</p>
          </div>
        </div>
        <div className="selector-icon">
          <Icon as={MdArrowDropDown} />
        </div>
      </div>
    </div>
  );
}

export function PortalSelectorModal(props: {
  onClose: () => void,
}) {
  const { onClose } = props;
  const store = useStore();
  const selectedPortal = useObserver(() => store.portal);
  const [portals, setPortals] = useState<Portal[]>([]);
  const [loading, setLoading] = useState(false);

  const selectPortal = (portal: Portal) => {
    store.setPortal(portal);
    onClose();
  }

  const loadPortals = async () => {
    setLoading(true);
    const portals = await PortalApi.getMyPortals();
    setPortals(portals);
    setLoading(false);
  }

  useEffect(() => {
    loadPortals();
  }, [])

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="portal-selector-modal" >
          <ModalHeader>选择页面</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="portal-list-box">
              {!portals.length ? (
                <div className="">
                  <p>暂无读我页面，快去新建一个？</p>
                  <Button onClick={() => {
                    history.push('/studio/portal/create');
                  }}>新建页面</Button>
                </div>
              ) : null}
              {portals.map((portal) => {
                return (
                  <StudioPortalCard selected={portal.id === (selectedPortal ? selectedPortal.id : null)} action={<></>} portal={portal} onClick={() => {
                    selectPortal(portal);
                  }} />
                );
              })}
            </div>
            {loading ? (
              <Spinner />
            ) : null}
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={() => {
              history.push('/studio/portal');
              onClose();
            }}>管理我的页面</Button>
            <Button colorScheme='blue' onClick={onClose}>
              取消
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}