import { ComponentStyleConfig, extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

// Oh, chakra, let me alone, please...

const Button: ComponentStyleConfig = {
  baseStyle: {
  },
  variants: {
    ghost: {
      backgroundColor: "#393939"
    }
  },
};


const Modal: ComponentStyleConfig = {
  baseStyle: (props) => ({
    dialog: {
      bg: "#333"
    }
  })
};

const Tabs: ComponentStyleConfig = {
  baseStyle: (props) => ({
    tabpanel: {
      p: 0,
    }
  })
};

const Menu: ComponentStyleConfig = {
  baseStyle: (props) => ({
    list: {
      p: {
        bg: "#333"
      },
    }
  })
}

export const chakraTheme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'brand',
  }), {
  colors: {
    brand: {
      100: "#BEE3F8",
      200: "#7dc1f2",
      300: "#63B3ED",
      400: "#4299E1",
      500: "#3182CE",
      600: "#2B6CB0",
      700: "#2C5282",
    },
    gray: {
      100: "#F5F5F5",
      200: "#E0E0E0",
      300: "#BDBDBD",
      400: "#757575",
      500: "#616161",
      600: "#424242",
      700: "#333333",
      800: "#212121",
      900: "#000000",
    },
  },
  components: {
    // Input,
    // Textarea,
    // NumberInput,
    Button,
    // Checkbox,
    // Radio,
    Modal,
    Tabs,
    Menu,
  },
  styles: {
    global: () => ({
      body: {
        bg: "",
      },
      '*, *::before, &::after': {
        wordWrap: "",
      },
    }),
    
  },
  breakpoints: null,
  config: {
    cssVarPrefix: 'readme',
    initialColorMode: 'dark',
    useSystemColorMode: false,
  }
});