import { Box, Button, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { NavLink, Outlet } from "react-router-dom";
import { MdBarChart, MdInfo, MdLogout, MdMenu, MdMenuOpen, MdModeEdit, MdPerson, MdPersonOff, MdSettings, MdShare } from 'react-icons/md'

import "../studio.scss";
import { useObserver } from "mobx-react-lite";
import { useStore } from "../../../stores/content";
import { useCallback, useState } from "react";
import { PortalSelector } from "../componenets/portalSelector";
import { history } from "../../../utils/history";

export function StudioLayout(props: {}) {
  const store = useStore();
  const user = useObserver(() => store.user);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const NavItem = useCallback((props: any) => {
    return (
      <NavLink onClick={() => {
        setIsMobileMenuOpen(false);
      }} className={({ isActive }) => `item ${isActive ? 'active' : ''}`} {...props}>
        {props.children}
      </NavLink>
    );
  }, []);

  return (
    <>
      <div className="studio-app">
        <div className="app-header-mobile">
          <div className="logo"><img src="/statics/readme-cool.svg" alt="logo" /></div>
          <Button className="mobile-menu" size={'sm'} variant={'ghost'} onClick={() => {
            setIsMobileMenuOpen(!isMobileMenuOpen);
          }}>
            {isMobileMenuOpen ? (
              <Icon as={MdMenuOpen} />
            ) : (
              <Icon as={MdMenu} />
            )}
          </Button>
        </div>
        <div className={`app-sidebar ${isMobileMenuOpen ? 'active' : ''}`}>
          <div className="app-sidebar-content">
          <div className="logo"><img src="/statics/readme-cool.svg" alt="logo" /></div>
          <PortalSelector />
          <div className="studio-nav-container">
          <div className="studio-nav">
            <NavItem end to="/studio/portal/edit">
              <Icon as={MdModeEdit} />编辑器
            </NavItem>
            <NavItem end to="/studio/portal/share">
              <Icon as={MdShare} />分享链接
            </NavItem>
            <NavItem end to="/studio/portal/analytics">
              <Icon as={MdBarChart} />数据统计
            </NavItem>
            <div className="hr"></div>
            <NavItem end to="/studio/portal">
              <Icon as={MdMenu} />我的页面
            </NavItem>
            <NavItem to="/studio/account">
              <Icon as={MdSettings} />帐户设置
            </NavItem>
            <div className="hr"></div>
            <NavItem to="/studio/about">
              <Icon as={MdInfo} />关于
            </NavItem>
            {/* <NavItem to="/studio/login">
              <Icon as={MdInfo} />临时注册登录
            </NavItem> */}
          </div>
          <div className="studio-nav studio-nav-footer">
            {user ? (
              <Menu>
                {({ isOpen }) => (
                  <>
                <MenuButton as={Box} className="item-wrapper">
                  <div className={`item ${isOpen ? 'active' : ''}`}>
                    <Icon as={MdPerson} />
                    {user.nickname}
                  </div>
                </MenuButton>
                <MenuList>
                  <MenuItem icon={<Icon as={MdSettings} />} onClick={() => history.push('/studio/account')}>账户设置</MenuItem>
                  <MenuItem
                  icon={<Icon as={MdLogout} />}
                  onClick={() => store.logout()}
                  >退出登录</MenuItem>
                </MenuList>
                 </>
                 )}
              </Menu>

            ) : (
              <NavItem to="/studio/login">
                <Icon as={MdPersonOff} />
                点击登录
              </NavItem>
            )}

          </div>
          </div>
          </div>
        </div>
        <div className="app-main">
          <Outlet />
          
        </div>
      </div>
    </>
  );
};
