import { useObserver } from "mobx-react-lite";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useStore } from "../../../stores/content";

export function RequireLogin () {
  const store = useStore();
  const user = useObserver(() => store.user);
  let location = useLocation();

  if(!user) {
    return (
      <Navigate to="/studio/login" state={{ from: location }} replace />
    );
  }
  
  return (
    <>
    <Outlet />
    </>
  );
}