export function StudioPageHeader(props: {
  title: string,
  subtitle?: string,
  children?: React.ReactNode,
}) {
  return (
    <div className="studio-page-header">
      <h2>{props.title}</h2>
      <p>{props.subtitle}</p>
      {props.children}
    </div>
  )
}