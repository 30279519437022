import { UpdatePortalData } from "../models/portal";
import { request } from "./request";

export class PortalApi {
  static async getPortal(url: string) {
    return (await request.requester.get(`/portals/p/${url}`)).data;
  }

  static async createPortal(data: UpdatePortalData) {
    return (await request.requester.post(`/portals`, data)).data;
  }

  static async updatePortal(data: UpdatePortalData, url: string) {
    return (await request.requester.post(`/portals/p/${url}`, data)).data;
  }

  static async getMyPortals() {
    return (await request.requester.get(`/portals/my`)).data;
  }
}
