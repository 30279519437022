import Axios, { AxiosError, AxiosInstance } from "axios";
import { GlobalDialog } from "../components/globalDialog";
import { history } from '../utils/history';

export const API_BASEURL = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:11451/';

class Request {
  protected _requester: AxiosInstance
  protected _token: string | null = '';

  constructor() {
    this._requester = Axios.create({
      baseURL: API_BASEURL,
    })
    this._requester.interceptors.response.use(res => res, async (err) => {
      await this.onRequestError(err)
    })
  }

  get requester() {
    return this._requester
  }

  get token() {
    return this._token;
  }

  onRequestError(err: AxiosError) {
    let errorMessage;

    if (err.response && err.response.data && err.response.data.message) {
      if(typeof err.response.data.message == 'object') {
        errorMessage = JSON.stringify(err.response.data.message);
      }else{
        errorMessage = err.response.data.message;
      }
    } else {
      errorMessage = JSON.stringify(err);
    }

    if (err.response && err.response.status === 401) {
      GlobalDialog.info({
        title: '登录失效或已经过期，请重新登录',
        content: ''
      })
      localStorage.removeItem('readme-token');
      history.push('/studio/login');
      return;
    }
    GlobalDialog.info({
      title: '',
      content: errorMessage
    })
    throw err;
  }

  public setToken(token: string | null) {
    if (token) {
      this._requester.defaults.headers.common['Authorization'] = `UserSession ${token}`
      this._token = token
    } else {
      this._requester.defaults.headers.common['Authorization'] = ''
      this._token = null
    }
  }

  public getAuthorizationHeaders() {
    return {
      'Authorization':  `UserSession ${this.token}`
    }
  }


}

export const request = new Request()