import { Tag, TagLeftIcon, TagLabel, Button } from "@chakra-ui/react";
import { MouseEventHandler, ReactElement } from "react";
import { MdHome, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Portal, PortalStatus } from "../../../models/portal";
import { useStore } from "../../../stores/content";
import { history } from "../../../utils/history";

export function StudioPortalCard(props: {
  portal: Portal,
  onClick?: MouseEventHandler<HTMLDivElement>
  action?: ReactElement
  selected?: boolean
}) {
  const store = useStore();
  const { portal, onClick, action, selected } = props;

  return (
    <div className={`item ${selected ? 'selected' : ''}`} onClick={onClick ? onClick : () => null}>
      <div className="content">
        <div className="tags">
          {portal.status === PortalStatus.Enabled ? (
            <Tag variant='subtle' colorScheme='cyan'>
              <TagLeftIcon boxSize='12px' as={MdVisibility} />
              <TagLabel>已发布</TagLabel>
            </Tag>
          ) : null}
          {portal.status === PortalStatus.Disabled ? (
            <Tag variant='subtle' colorScheme='cyan'>
              <TagLeftIcon boxSize='12px' as={MdVisibilityOff} />
              <TagLabel>已隐藏</TagLabel>
            </Tag>
          ) : null}
          {!portal.alias ? (
            <Tag style={{marginLeft: '5px'}} variant='subtle' colorScheme='green'>
              <TagLeftIcon boxSize='12px' as={MdHome} />
              <TagLabel>主页</TagLabel>
            </Tag>
          ) : null}
        </div>
        <h3>{portal.name}</h3>
        <p>{process.env.REACT_APP_PUBLIC_URL}/{portal.url}</p>
      </div>

      {action ? action : (
        <>
          <div className="action">
            <Button size={'sm'} onClick={() => {
              store.setPortal(portal);
              history.push('/studio/portal/edit');
            }}>
              编辑
            </Button>
            <Button size={'sm'} variant="ghost" onClick={() => {
              store.setPortal(portal);
              history.push('/studio/portal/share');
            }}>
              分享
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
