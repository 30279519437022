import { Auth } from "../models/auth";
import { User } from "../models/user";
import { request } from "./request";

export class AuthApi {
  static async login(data: {
    username: string,
    password: string
  }) {
    return (await request.requester.post(`/auth/login/password`, data)).data;
  }

  static async register(data: {
    username: string,
    password: string,
    nickname: string,
  }) {
    return (await request.requester.post<{
      user: User,
      auth: Auth,
    }>(`/auth/register`, data)).data;
  }

  static async getSessionInfo() {
    return (await request.requester.get<{
      user: User,
      token: Auth,
    }>("/auth")).data;
  }
  

}
