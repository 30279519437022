import { lazy, ReactNode, Suspense } from "react";
import { Route } from "react-router-dom";
import { Loading } from "../../components/loading";

import "./main.scss";
import './theme.scss';

const ReadmeHome = lazy(() => import("./home"));
const ReadmePortalPage = lazy(() => import("./portal"));
const ReadmePortalPreviewPage = lazy(() => import("./portal/preview"));

const suspenseEl = (component: ReactNode) => {
  return <Suspense fallback={<Loading height={'100vh'} />}>{component}</Suspense>
}

export function SiteModule() {
  return (
    <>
      <Route path="/" element={suspenseEl(<ReadmeHome />)} />
      <Route path="/:username" element={suspenseEl(<ReadmePortalPage />)} />
      <Route path="/:username/:alias" element={suspenseEl(<ReadmePortalPage />)} />
      <Route path="/portal/preview" element={suspenseEl(<ReadmePortalPreviewPage />)} />
    </>
  )
}