import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { StudioModule } from "./app/studio";
import { chakraTheme } from "./utils/chakraTheme";
import { useEffect, useState } from "react";
import { useStore } from "./stores/content";
import ReactGA from 'react-ga';

import "./reset.scss"
import "./common.scss"
import "focus-visible/dist/focus-visible"
import { SiteModule } from "./app/site";
import { DialogArea } from "./components/globalDialog";
import { Loading } from "./components/loading";
import { initAnalyticsOnHistory } from "./utils/history";

function App() {
  const store = useStore();
  const [isReady, setIsReady] = useState<boolean>(false);


  useEffect(() => {
    const init = async () => {
      await store.resumeUserSession();
      ReactGA.initialize('G-JYSNLQEJ24', {
        // debug: true,
      });
      initAnalyticsOnHistory();
      setIsReady(true);
    }
    init();
  }, [store]);

  return (
    <ChakraProvider resetCSS={false} theme={chakraTheme} cssVarsRoot='#readme-app' >
      <div className="App readme-app" id="readme-app">
        <DialogArea />
        {isReady ? (
          <Routes>
            {StudioModule()}
            {SiteModule()}

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>404</p>
                </main>
              }
            />
          </Routes>
        ) : (
          <Loading style={{height: '100vh', width: '100vw'}} />
        )}
      </div>
    </ChakraProvider>
  );
}

export default App;
