import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState, ReactElement } from "react";
import { EventBus, GlobalEventType } from "../utils/eventBus";

export interface IGlobalDialog {
  onClose: () => void,
  content: (onClose: Function) => JSX.Element,
  isOpen: boolean,
};

export function DialogArea() {
  const [dialogs, setDialogs] = useState<IGlobalDialog[]>([]);

  const closeDialog = (index: number) => {
    let data = dialogs;
    data.splice(index, 1);
    setDialogs([...data]);
  }

  useEffect(() => {
    EventBus.on(GlobalEventType.NewDialog, (data) => {
      setDialogs([...dialogs, {
        ...data,
      }]);
    });
  }, [dialogs])
  
  return (
    <>
      {dialogs.map((dialogItemData, dialogItemIndex) => {
        return (

          <Modal isCentered={true} key={dialogItemIndex} isOpen={dialogItemData.isOpen} onClose={() => {
            dialogItemData.onClose();
            closeDialog(dialogItemIndex)
          }}>
            <ModalOverlay />
            <ModalContent>
              {dialogItemData.content(() => {
                dialogItemData.onClose();
                closeDialog(dialogItemIndex)
              })
              }
            </ModalContent>
          </Modal>
        );
      })}
    </>
  )
}

export function openDialog(props: {
  onClose: () => void,
  content: (onClose: Function) => JSX.Element,
}) {
  return EventBus.emit(GlobalEventType.NewDialog, {
    ...props,
    isOpen: true,
  });
}

export class GlobalDialog {
  public static async info(data: {
    title: string,
    content: string | ReactElement,
  }) {
    return openDialog({
      onClose: () => {
      },
      content: (onClose: Function) => {
        return (
          <>

            <ModalHeader>{data.title}</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody>
              {data.content}
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => { onClose() }}>确认</Button>
            </ModalFooter>

          </>
        )
      }
    })
  }
} 