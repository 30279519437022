import { BaseModel } from "./base";
import { User } from "./user";

export enum PortalStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Archived = 'archived',
}

export interface Portal extends BaseModel {
  name: string,
  description: string,
  url: string,
  alias: string,
  data: PortalData,
  status: PortalStatus,

  userId: number,
  user?: User,
}

export type UpdatePortalData = {
  name?: string,
  description?: string,
  alias?: string,
  data?: PortalData,
  status?: PortalStatus,
}

// 页面 Data
export interface PortalData {
  sections: PortalSection[];
  template: PortalPageTemplate;
  style: PortalPageStyle;
}

// 页面区块

export enum PortalSectionType {
  Header = 'header',
  LinkWall = 'link_wall',
  Text = 'text',
  Image = 'image',
  Video = 'video', // 未实现
}

export type PortalSection =
  | PortalSectionLinkWall
  | PortalSectionMarkdown
  | PortalSectionImage
  | PortalSectionHeader;

export interface PortalSectionCommon {
  // TODO: Section 公共数据
  key?: string | number;
}

export interface PortalSectionHeader extends PortalSectionCommon {
  type: PortalSectionType.Header;
  data: {
    logo: string;
    title: string;
    description: string;
  }
}

export interface PortalSectionLinkWall extends PortalSectionCommon {
  type: PortalSectionType.LinkWall;
  data: {
    links: {
      title: string;
      href: string;
      icon?: string;
    }[];
  };
}

export interface PortalSectionMarkdown extends PortalSectionCommon {
  type: PortalSectionType.Text;
  data: {
    value: string; // markdown area
  };
}

export interface PortalSectionImage extends PortalSectionCommon {
  type: PortalSectionType.Image;
  data: {
    src: string; // image url
  };
}

// 页面风格和模板

export enum PortalPageTemplate {
  Default = 'default',
  Light = 'light',
  Transparent = 'transparent'
}

export interface PortalPageStyle {
  backgroundType?: PortalPageStyleBackgroundType;
  attachBackgroundStyle?: React.CSSProperties;
  uploadedBackgroundImage?: string[];
}

export enum PortalPageStyleBackgroundType {
  None = 'none',
  Preset = 'preset',
  Image = 'image',
}
