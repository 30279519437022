import EventEmitter from "events";

export const EventBus = new EventEmitter();

export enum GlobalEventType {
  // 全局弹窗
  NewDialog = 'new_dialog',
  // 通知 portal预览 更新
  UpdatePortal = 'update_portal',
  // Portal预览通知父页面 更新
  RequestUpdatePortal = 'request_update_portal',
}